<template>
    <v-dialog max-width="900" v-model="dialog">
        <template v-slot:activator="{ on }">
            <div v-on="on">
                <slot></slot>
            </div>
        </template>
        <v-card flat :class="`shepherd-assignment-intro-${this.bundleData.bid}`">
            <v-card-title>
                <div class="d-flex align-center w-100">
                    <span class="text-uppercase secondary--text ml-1">{{ bundleData.long_id }}</span>
                    <v-spacer></v-spacer>
                    <div v-if="bundleData.bundle_type_name === distributionReasons.ASSIGNMENT && !isSpaceAdmin">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn class="mr-1" @click="tour.start()" v-on="on" icon small><v-icon small>help_outline</v-icon></v-btn>
                            </template>
                            <span>Show tour</span>
                        </v-tooltip>
                        <span :class="`shepherd-handin-button-${bundleData.bid}`" v-if="!isAssignmentOverdue(bundleData.allow_handins_until) && !isSpaceAdmin">
                            <TheSnapshotAssignmentSubmitDialog :bundleData="bundleData" />
                        </span>
                        <span v-else :class="`caption shepherd-handin-button-${bundleData.bid}`">Deadline has passed, no further hand-ins allowed</span>
                    </div>
                    <div v-if="bundleData.bundle_type_name === distributionReasons.ASSIGNMENT && isSpaceAdmin">
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-on="on">
                                    <v-btn
                                        color="primary"
                                        :disabled="!isAssignmentOverdue(bundleData.allow_handins_until)"
                                        @click="startGrading()"
                                        v-bind="attrs"
                                        class="ml-2"
                                        elevation="0">
                                        <v-icon class="mr-1">grading</v-icon>
                                        Grade
                                    </v-btn>
                                </span>
                            </template>
                            <span v-if="isAssignmentOverdue(bundleData.allow_handins_until)">Assignment deadline has passed, grading can now be started.</span>
                            <span v-else>Assignment grading can be started only after the due date has passed.</span>
                        </v-tooltip>
                    </div>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-alert type="info" text :class="`mt-5 shepherd-assignment-description-${bundleData.bid}`">
                    <div class="d-flex justify-center align-center flex-row w-100">
                        <div>
                            <div v-if="bundleData.description">
                                {{ bundleData.description }}
                            </div>
                            <div v-if="bundleData.allow_handins_until" class="d-flex align-center my-2">
                                <v-icon class="mr-1" small color="info">alarm</v-icon>
                                Due: {{ bundleData.allow_handins_until | dateTimeToHuman }}
                            </div>
                            <div class="mt-1 caption" v-if="bundleData.creation_timestamp">
                                <span>Created</span>
                                : {{ bundleData.creation_timestamp | dateTimeToHuman }}
                            </div>
                        </div>
                        <v-spacer />
                        <TheBundleEditDialog v-if="isSpaceAdmin && bundleData.bundle_type_name === distributionReasons.ASSIGNMENT" :bundleData="bundleData">
                            <template v-slot:dialogactivator>
                                <v-btn outlined color="primary">
                                    <v-icon class="mr-1">edit</v-icon>
                                    Edit
                                </v-btn>
                            </template>
                        </TheBundleEditDialog>
                    </div>
                </v-alert>
                <v-tabs v-model="tab">
                    <v-tab>Details</v-tab>
                    <v-tab v-if="bundleData.bundle_type_name === distributionReasons.ASSIGNMENT && isSpaceAdmin">
                        <v-icon class="mr-1">grading</v-icon>
                        Grades
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <div v-if="bundleData.bundle_type_name === distributionReasons.ASSIGNMENT && grade && !isSpaceAdmin" class="mt-7 mb-5 d-flex">
                            <div>
                                <div class="secondary--text subtitle-1 font-weight-bold">Grade</div>
                                Your grade for this assignment is
                                <span class="font-weight-bold">{{ grade }}</span>
                            </div>
                            <v-spacer />
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" color="primary">
                                        <v-icon class="mr-1">visibility</v-icon>
                                        Review Corrections
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="a in applications"
                                        :key="a.aid"
                                        :to="{
                                            name: 'app-open',
                                            params: {
                                                oid: $route.params.oid,
                                                sid: $route.params.sid,
                                                iid: $route.params.iid,
                                                snid: $route.params.snid,
                                                aid: a.aid
                                            },
                                            query: { handback_bid: bundleData.bid }
                                        }">
                                        <v-list-item-title>{{ a.long_id }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        <div
                            v-else-if="
                                bundleData.bundle_type_name === distributionReasons.ASSIGNMENT &&
                                !bundleData.handbacks_visible &&
                                isAssignmentOverdue(bundleData.allow_handins_until)
                            "
                            class="mt-7 mb-5">
                            This assignment is currently being graded and hand-backs / grades are not yet visible.
                        </div>
                        <div
                            v-else-if="
                                bundleData.bundle_type_name === distributionReasons.ASSIGNMENT &&
                                bundleData.handbacks_visible &&
                                isAssignmentOverdue(bundleData.allow_handins_until) &&
                                !grade &&
                                !isSpaceAdmin
                            "
                            class="mt-7 mb-5">
                            You haven't received a grade for this assignment.
                        </div>
                        <div
                            v-else-if="
                                bundleData.bundle_type_name === distributionReasons.ASSIGNMENT &&
                                !isAssignmentOverdue(bundleData.allow_handins_until) &&
                                !isSpaceAdmin
                            "
                            class="mt-7 mb-5">
                            The assignment deadline has not yet passed, you can continue to hand-in solutions using the Hand-In button above.
                        </div>
                        <v-expansion-panels v-model="panel" flat multiple class="w-100">
                            <v-expansion-panel v-if="bundleData.objects.files.length" :class="`shepherd-assignment-files-${bundleData.bid}`">
                                <v-expansion-panel-header class="px-0">
                                    <span class="secondary--text font-weight-bold">Files ({{ bundleData.objects.files.length }})</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-list class="pa-0" dense>
                                        <v-list-item-group class="pa-0 ma-0" color="secondary">
                                            <v-list-item style="max-height: 5px" v-for="file in bundleData.objects.files" :key="file" class="pa-0">
                                                <v-list-item-avatar>
                                                    <v-icon small>mdi-file-outline</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title class="subtitle-2">{{ file.slice(6) }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel v-if="bundleData.bundle_type_name === distributionReasons.ASSIGNMENT && !isSpaceAdmin">
                                <v-expansion-panel-header class="px-0">
                                    <span class="secondary--text font-weight-bold">My Submissions ({{ bundleData.handins.length }})</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div class="d-flex justify-end">
                                        <v-btn text outlined @click="$store.dispatch('spaceStore/fetchSpaceBundles', $route.params.sid)" small>
                                            <v-icon small class="mr-1">refresh</v-icon>
                                            Refresh
                                        </v-btn>
                                    </div>
                                    <v-list class="pa-0" dense v-if="bundleData.handins && bundleData.handins.length">
                                        <v-list-item-group class="pa-0 ma-0" color="secondary">
                                            <v-list-item style="max-height: 10px" class="pa-0" v-for="(submission, idx) in bundleData.handins" :key="idx">
                                                <v-list-item-avatar>
                                                    <v-icon small>assignment_turned_in</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title class="subtitle-2">
                                                        {{ submission.long_id }} (submitted on: {{ submission.handin_timestamp | dateTimeToHuman }})
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                    <v-alert type="info" v-else class="mt-2">
                                        No handins yet, please click the refresh button above to fetch them if you recently handed one in.
                                    </v-alert>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-divider />
                            <v-expansion-panel v-if="bundleData.objects.tables.length">
                                <v-expansion-panel-header class="px-0">
                                    <span class="secondary--text font-weight-bold">Tables ({{ bundleData.objects.tables.length }})</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-list class="pa-0" dense>
                                        <v-list-item-group class="pa-0 ma-0" color="secondary">
                                            <v-list-item style="max-height: 5px" v-for="table in bundleData.objects.tables" :key="table" class="pa-0">
                                                <v-list-item-avatar>
                                                    <v-icon small>mdi-table-large</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title class="secondary--text subtitle-2">{{ table }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel v-if="bundleData.objects.applications.length && false">
                                <v-expansion-panel-header class="px-0">
                                    <span class="secondary--text font-weight-bold">Applications ({{ bundleData.objects.applications.length }})</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-list class="pa-0" dense>
                                        <v-list-item-group class="pa-0 ma-0" color="secondary">
                                            <v-list-item
                                                style="max-height: 5px"
                                                v-for="application in bundleData.objects.applications"
                                                :key="application"
                                                class="pa-0">
                                                <v-list-item-avatar>
                                                    <v-icon small>desktop_windows</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title class="secondary--text subtitle-2">{{ application }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-tab-item>
                    <v-tab-item v-if="bundleData.bundle_type_name === distributionReasons.ASSIGNMENT">
                        <the-snapshot-assignment-grades
                            :bid="bundleData.bid"
                            @closeModal="closeModal"
                            :overdue="isAssignmentOverdue(bundleData.allow_handins_until)"
                            :bundleData="bundleData" />
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import Shepherd from 'shepherd.js'
import { assignmentMethods } from '@/mixins/assignments'
import { enumsData } from '@/mixins/enums'
import { mapGetters, mapState } from 'vuex'
import tour from '@/mixins/tour'

const TheSnapshotAssignmentSubmitDialog = () => import('./TheSnapshotAssignmentSubmitDialog')
const TheSnapshotAssignmentGrades = () => import('./TheSnapshotAssignmentGrades')
const TheBundleEditDialog = () => import('./TheSnapshotBundleEditDialog')

export default {
    name: 'SnapshotBundleDialog',
    mixins: [assignmentMethods, enumsData, tour],
    components: {
        TheSnapshotAssignmentGrades,
        TheSnapshotAssignmentSubmitDialog,
        TheBundleEditDialog
    },
    props: {
        bundleData: Object,
        isIcon: { type: Boolean, default: false },
        defaultPanel: { type: Number, default: 0 }
    },
    data() {
        return {
            panel: [0, 1],
            tab: this.$props.defaultPanel,
            dialog: false,
            grade: undefined,
            tour: null
        }
    },
    computed: {
        ...mapGetters('instanceStore', ['isMasterInstance', 'isDistributedInstance']),
        ...mapGetters('spaceStore', ['isSpaceAdmin']),
        ...mapState('snapshotStore', ['applications']),
        ...mapState(['userOrgs', 'userMetadata']),
        showAssignmentTour() {
            return (
                this.dialog &&
                this.bundleData.bundle_type_name === this.distributionReasons.ASSIGNMENT &&
                !this.isSpaceAdmin &&
                !this.isAssignmentOverdue(this.bundleData.allow_handins_until)
            )
        }
    },
    mounted() {
        // const self = this
        this.tour = new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
                cancelIcon: {
                    enabled: true
                },
                classes: 'secondary--text',
                scrollTo: { behavior: 'smooth' }
            }
        })

        this.tour.addStep({
            title: 'Welcome to assignments!',
            text: `Working with assignments on Nuvolos is easy! \
  We will guide you through the most important steps.`,
            id: 'grading-intro',
            buttons: [
                {
                    action() {
                        return this.next()
                    },
                    text: 'Next'
                }
            ]
        })

        this.tour.addStep({
            title: 'The assignment overview',
            text: `This dialog shows you the most important information about the assignment. \
  Let's walk through it!`,
            attachTo: {
                element: `.shepherd-assignment-intro-${this.bundleData.bid}`,
                on: 'bottom'
            },
            buttons: [
                {
                    action() {
                        return this.back()
                    },
                    classes: 'shepherd-button-primary',
                    text: 'Back'
                },
                {
                    action() {
                        return this.next()
                    },
                    text: 'Next'
                }
            ],
            id: 'assignment-intro'
        })

        this.tour.addStep({
            title: 'Assignment description',
            text: `Here is the quick summary of the assignment. \
            You can only submit solutions before the due-date!`,
            attachTo: {
                element: `.shepherd-assignment-description-${this.bundleData.bid}`,
                on: 'top'
            },
            buttons: [
                {
                    action() {
                        return this.back()
                    },
                    classes: 'shepherd-button-primary',
                    text: 'Back'
                },
                {
                    action() {
                        return this.next()
                    },
                    text: 'Next'
                }
            ],
            id: 'assignment-description'
        })

        this.tour.addStep({
            title: 'Assignment files',
            text: `Here are the files / folders for the assignment. \
            You are expected to work inside these files / folders, <b>do not rename or move</b> them!`,
            attachTo: {
                element: `.shepherd-assignment-files-${this.bundleData.bid}`,
                on: 'top'
            },
            buttons: [
                {
                    action() {
                        return this.back()
                    },
                    classes: 'shepherd-button-primary',
                    text: 'Back'
                },
                {
                    action() {
                        return this.next()
                    },
                    text: 'Next'
                }
            ],
            id: 'assignment-files'
        })

        this.tour.addStep({
            title: 'Handing-in assignments',
            text: `Once you are ready with editing the assignment files \
            click the hand-in button to submit them to the instructor for evaluation.`,
            attachTo: {
                element: `.shepherd-handin-button-${this.bundleData.bid}`,
                on: 'left'
            },
            buttons: [
                {
                    action() {
                        return this.back()
                    },
                    classes: 'shepherd-button-primary',
                    text: 'Back'
                },
                {
                    action() {
                        return this.next()
                    },
                    text: 'Next'
                }
            ],
            id: 'assignment-handin'
        })

        this.tour.addStep({
            title: 'Instructor feedback',
            text: `Once the instructor has graded the assignment, come back here to check your grade and corrections.`,
            attachTo: {
                element: `.shepherd-assignment-intro-${this.bundleData.bid}`,
                on: 'bottom'
            },
            buttons: [
                {
                    action() {
                        return this.complete()
                    },
                    text: 'Got it!'
                }
            ],
            id: 'assignment-feedback'
        })
    },
    methods: {
        closeModal() {
            this.dialog = false
        },
        startGrading() {
            this.tab = 1
        }
    },
    watch: {
        dialog(to) {
            if (to) {
                this.$axios.get(`/bundles/${this.bundleData.bid}/instance/${this.$route.params.iid}/grade`).then(response => {
                    this.grade = response.data
                })
            } else {
                this.tour.complete()
            }
        },
        showAssignmentTour(to) {
            if (to) {
                this.$nextTick(() => {
                    if (!this.userMetadata.has_seen_assignment_student_intro) {
                        this.$store.commit('setUserMetadata', { has_seen_assignment_student_intro: true })
                        this.tour.start()
                    }
                })
            } else {
                this.tour.complete()
            }
        }
    }
}
</script>
